/*
  GLOBAL VARIABLES
  Import this file to any scss files for use variables
  Please, check variables before adding new
  If you rename a variable - rename it everywhere
*/
/* Branding colors */
/**********************************************
              SCREEN VARIABLES
**********************************************/
.Form {
  margin-bottom: 35px; }
  @media (max-width: 767.8px) {
    .Form {
      border-top: 1px solid rgba(140, 140, 140, 0.5);
      padding: 8px 10px 0; } }
  .Form .FormHeader {
    text-transform: none;
    margin-bottom: 27px; }
    @media (max-width: 767.8px) {
      .Form .FormHeader {
        margin-bottom: 10px; } }
  .Form p {
    line-height: 21px;
    font-size: 16px;
    color: #575757;
    font-weight: 600; }
  .FormControl {
    display: flex;
    border-radius: 2px;
    overflow: hidden;
    height: 45px;
    border: 1px solid #ffb85e; }
    .FormControl input {
      padding: 12px 20px;
      line-height: 21px;
      font-size: 16px;
      height: 100%;
      border-radius: 2px;
      margin: 0;
      font-weight: 600;
      border-color: transparent;
      border-top-left-radius: 7px;
      border-bottom-left-radius: 7px; }
    .FormControl button {
      height: 100%;
      margin: 0;
      border-radius: 0;
      width: 70px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--accent-color);
      border: 2px solid var(--accent-color); }
      .FormControl button:hover {
        background: #f6db83;
        border-color: #f6db83; }
      .FormControl button[disabled], .FormControl button:active {
        background: #FFDBAF;
        border-color: #FFDBAF; }
