/*
  GLOBAL VARIABLES
  Import this file to any scss files for use variables
  Please, check variables before adding new
  If you rename a variable - rename it everywhere
*/

/* Branding colors */
$color-primary: var(--accent-color);
$color-primary-pressed: #F2B39D;
$color-primary-hover: var(--accent-color);

$color-yellow: var(--accent-color);
$color-yellow-pressed: #FFDBAF;
$color-yellow-hover: #f6db83;
$let-color-grey-hover: rgba(#8C8C8C, .7);
$let-color-grey-pressed: rgba(#8C8C8C, .5);
$let-color-green-hover: rgba(#4CAF50, .7);
$let-color-green-pressed: rgba(#4CAF50, .5);

$color-black: #575757;
$color-grey: #868686;
$color-light-grey: #faf8f5;
$color-white: #FFFFFF;

$color-red: #F61618;
$color-green: #1EC982;
$color-inactive: #BCC5CA;
$color-disabled: #DEE2E5;

$let-color-grey: #8C8C8C;
$let-color-green: #4CAF50;
$let-color-c4: #C4C4C4;

$orange-light: var(--accent-color);
$orange: $color-primary;
/**********************************************
              SCREEN VARIABLES
**********************************************/
$breakpoint-desktop: 1440px;
$breakpoint-laptop: 1200px;
$breakpoint-ipad: 1024px;
$breakpoint-tablet: 768px;
$breakpoint-phone: 576px;

// From desktop
@mixin laptop {
  @media (max-width: $breakpoint-desktop - 0.2px) { //1439px
    @content;
  }
}

// From laptop
@mixin ipad {
  @media (max-width: $breakpoint-laptop - 0.2px) { //1220px
    @content;
  }
}

// From ipad
@mixin tablet {
  @media (max-width: $breakpoint-ipad - 0.2px) { //1024px
    @content;
  }
}

// From tablet
@mixin landscape {
  @media (max-width: $breakpoint-tablet - 0.2px) { //768px
    @content;
  }
}

// From landscape
@mixin phone {
  @media (max-width: $breakpoint-phone - 0.2px) { //576px
    @content;
  }
}


@mixin h1($font-size: 30px, $margin: 0) {
  font-family: var(--font-family-title);

  font-weight: 700;
  font-size: $font-size;
  line-height: 1.66em;
  margin: $margin;

  @include landscape {
    font-size: 19px;
  }
}

@mixin container($padding-top: 0, $padding-bottom: 0) {
  padding: $padding-top 15px $padding-bottom;
  width: 100%;
  margin: 0 auto;
  max-width: 1200px;

  @include phone {
    padding: $padding-top 10px $padding-bottom;
  }
}

@mixin ticketBtn {
  font-weight: 600;
  font-size: 14px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  line-height: 26px;
  color: #fff;
  text-transform: uppercase;
  position: relative;
  transform: scale(1);
  transition: transform .25s ease-in-out;
  span {
    position: relative;
    z-index: 2;
  }

  svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;

    path {
      transition: 0.2s;
    }
  }


  &:hover {
    transform: scale(1.05);
  }
  &[disabled],
  &:active svg path {
    cursor: not-allowed;
  }
}
