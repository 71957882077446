/*
  GLOBAL VARIABLES
  Import this file to any scss files for use variables
  Please, check variables before adding new
  If you rename a variable - rename it everywhere
*/
/* Branding colors */
/**********************************************
              SCREEN VARIABLES
**********************************************/
.Component {
  background-color: var(--accent-color);
  padding: 12px 0; }

.Content {
  display: flex;
  align-items: center;
  justify-content: center; }
  @media (max-width: 575.8px) {
    .Content {
      flex-direction: column; } }

.Title {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  margin-right: 16px; }
  @media (max-width: 575.8px) {
    .Title {
      margin-right: 0;
      margin-bottom: 8px;
      font-size: 14px;
      text-align: center; } }
  .Title strong {
    font-weight: 700; }

.ReadMore {
  width: 168px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Comfortaa', sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 26px;
  text-align: center;
  color: var(--accent-color);
  background-color: #F8D6CA;
  border-radius: 7px;
  flex-shrink: 0; }
