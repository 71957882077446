/*
  GLOBAL VARIABLES
  Import this file to any scss files for use variables
  Please, check variables before adding new
  If you rename a variable - rename it everywhere
*/
/* Branding colors */
/**********************************************
              SCREEN VARIABLES
**********************************************/
.Container {
  background: #faf8f5 url(assets/img/bg-navbar.svg) center no-repeat;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 120;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateX(100%);
  transition: 0.6s; }

.Open {
  transform: translateX(0); }

.LanguageSwitcher {
  position: absolute;
  top: 25px;
  left: 20px; }

.CloseBtn {
  position: absolute;
  top: 20px;
  right: 20px; }

.Nav a, .Nav button {
  line-height: 25px;
  font-size: 18px;
  text-align: center;
  color: #575757;
  display: block;
  margin: 0 auto 20px; }
  .Nav a:last-child, .Nav button:last-child {
    margin-bottom: 0; }
