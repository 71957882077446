/*
  GLOBAL VARIABLES
  Import this file to any scss files for use variables
  Please, check variables before adding new
  If you rename a variable - rename it everywhere
*/
/* Branding colors */
/**********************************************
              SCREEN VARIABLES
**********************************************/
.btn-green {
  background: #4CAF50;
  border-radius: 2px;
  width: 300px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 26px;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF;
  margin: 0 auto; }
  @media (max-width: 575.8px) {
    .btn-green {
      width: 100%; } }

.btn-green:disabled {
  background: #8C8C8C;
  cursor: not-allowed; }

.btn-green:disabled:hover, .btn-green:disabled:active {
  background: rgba(140, 140, 140, 0.7); }

.btn-green:hover {
  background: rgba(76, 175, 80, 0.7); }

.btn-green:active {
  background: rgba(76, 175, 80, 0.5); }
