/*
  GLOBAL VARIABLES
  Import this file to any scss files for use variables
  Please, check variables before adding new
  If you rename a variable - rename it everywhere
*/
/* Branding colors */
/**********************************************
              SCREEN VARIABLES
**********************************************/
.header-btns button, .header-btns a {
  color: #ffffff;
  background: var(--accent-color);
  border-radius: 2px;
  width: 160px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: scale(1);
  transition: transform .25s ease-in-out; }
  .header-btns button:hover, .header-btns a:hover {
    transform: scale(1.05); }

@media (max-width: 1199.8px) {
  .header-btns {
    display: none; } }

.nameShort {
  display: none; }

.User {
  display: flex;
  align-items: center;
  min-width: 240px; }
  .UserIcon {
    flex-shrink: 0;
    border-radius: 10em;
    margin-right: 20px;
    background-color: #8C8C8C;
    color: #ffffff;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var(--font-family);
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 28px; }
  @media (max-width: 1199.8px) {
    .User {
      position: absolute;
      right: 100px; } }
  @media (max-width: 767.8px) {
    .User {
      right: 50px;
      font-size: 14px;
      min-width: auto; }
      .UserIcon {
        margin-right: 10px;
        width: 30px;
        height: 30px;
        font-size: 14px; }
      .User .nameShort {
        display: inline-block; }
      .User .nameFull {
        display: none; } }

.Component {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  padding: 10px 0;
  z-index: 20;
  transition: 0.1s;
  background: #FFFFFF;
  box-shadow: 4px 4px 16px rgba(11, 12, 36, 0.03);
  font-family: var(--font-family);
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px; }
  @media (max-width: 767.8px) {
    .Component {
      padding: 18px 0; } }

.Fixed {
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transform: translateY(0); }

.Container {
  padding: 0 15px 0;
  width: 100%;
  margin: 0 auto;
  max-width: 1200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 15px;
  position: relative;
  z-index: 2; }
  @media (max-width: 575.8px) {
    .Container {
      padding: 0 10px 0; } }

.Logo {
  cursor: pointer;
  transform: translateY(3px); }
  @media (max-width: 1439.8px) {
    .Logo {
      flex-basis: unset; } }
  @media (max-width: 767.8px) {
    .Logo svg {
      height: 20px;
      width: auto; } }

.SidebarBtn {
  display: none; }
  @media (max-width: 1199.8px) {
    .SidebarBtn {
      display: block; }
      .SidebarBtn svg {
        height: 20px;
        width: auto; } }
  @media (max-width: 767.8px) {
    .SidebarBtn {
      margin-left: 30px; }
      .SidebarBtn svg {
        height: auto; } }

.Nav {
  text-align: center;
  display: flex; }
  .Nav a {
    background: transparent;
    border-radius: 2px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 170px;
    color: #575757;
    flex-shrink: 0;
    line-height: 28px;
    margin-right: 40px;
    font-size: 16px;
    border-bottom: 1px solid transparent; }
    @media (max-width: 1199.8px) {
      .Nav a {
        line-height: 30px !important; } }
    .Nav a:first-child {
      margin-left: 30px; }
    .Nav a:hover {
      background: #fff2de; }
    .Nav a:active {
      background: rgba(255, 242, 222, 0.7); }
  @media (max-width: 1199.8px) {
    .Nav {
      display: none; } }

.ml {
  margin-left: 250px;
  margin-right: 0; }

.LanguageSwitcher ul {
  background: #fff;
  border-radius: 5px;
  display: flex;
  flex-direction: column; }
  .LanguageSwitcher ul li {
    display: flex;
    justify-content: flex-end; }
  .LanguageSwitcher ul button {
    margin: 0; }

.LanguageSwitcher button:first-child {
  margin-top: 0; }

.Actions {
  display: flex;
  margin-right: 60px;
  align-items: center;
  width: 55px; }

@media (max-width: 1199.8px) {
  .LanguageSwitcher {
    display: none; }
  .Actions {
    margin-right: 20px; } }
  @media (max-width: 1199.8px) and (max-width: 767.8px) {
    .Actions {
      margin-right: 8px; } }

.header-not-found {
  background-color: #FAF8F5;
  box-shadow: none; }

.padding-block-dashboard {
  padding-top: 120px !important; }
