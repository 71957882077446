/*
  GLOBAL VARIABLES
  Import this file to any scss files for use variables
  Please, check variables before adding new
  If you rename a variable - rename it everywhere
*/
/* Branding colors */
/**********************************************
              SCREEN VARIABLES
**********************************************/
.Component {
  border-radius: 2px;
  border-width: 2px;
  border-style: solid;
  line-height: 1.857em;
  font-size: 14px;
  padding: 4px 10px;
  min-width: 200px;
  font-weight: 600;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  transition: background .35s ease; }

.Color-primary, .Color-primary-outline {
  color: #FFFFFF;
  background-color: var(--accent-color);
  border-color: var(--accent-color); }
  .Color-primary:hover, .Color-primary-outline:hover {
    background: var(--accent-color);
    border-color: var(--accent-color); }
  .Color-primary:active, .Color-primary-outline:active {
    background: #F2B39D;
    border-color: #F2B39D; }
  .Color-primary:disabled, .Color-primary-outline:disabled {
    background: #8c8c8c;
    border: 2px solid #8c8c8c;
    cursor: not-allowed; }

.Color-primary-light {
  color: #fff;
  background-color: var(--accent-color);
  border-color: var(--accent-color);
  transform: scale(1);
  transition: transform .25s ease-in-out; }
  .Color-primary-light:hover {
    transform: scale(1.05); }

.Color-primary-outline {
  background: #fff;
  color: var(--accent-color); }
  .Color-primary-outline:active, .Color-primary-outline:hover {
    background: #fff; }

.Color-yellow, .Color-yellow-outline {
  color: #575757;
  background-color: var(--accent-color);
  border-color: var(--accent-color); }

.Color-yellow-outline {
  background: #fff; }
  .Color-yellow-outline:hover {
    background: var(--accent-color);
    color: #FFFFFF; }
  .Color-yellow-outline:active {
    background: #FFDBAF;
    color: #FFFFFF; }

.TextTransform-uppercase {
  text-transform: uppercase; }

.TextTransform-lowercase {
  text-transform: lowercase; }

.TextTransform-capitalize {
  text-transform: capitalize; }
