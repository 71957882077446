.Preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #FAF8F5;
  display: flex;
  z-index: 10000;

  svg {
    margin: auto;
    height: auto;
    width: 160px;
    animation: 1.4s infinite heart;
  }
}

@keyframes heart {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.2);
  }
  40% {
    transform: scale(1.1);
  }
  60% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}