/*
  GLOBAL VARIABLES
  Import this file to any scss files for use variables
  Please, check variables before adding new
  If you rename a variable - rename it everywhere
*/
/* Branding colors */
/**********************************************
              SCREEN VARIABLES
**********************************************/
.Transition-enter {
  opacity: 0.01; }

.Transition-enter-active {
  opacity: 1;
  transition: 300ms ease-in; }

.Transition-exit {
  opacity: 1; }

.Transition-exit-active {
  opacity: 0.01;
  transition: 300ms ease-out; }

.italic {
  font-style: italic;
  font-weight: 300;
  font-size: 16px;
  line-height: 21px; }

.timePickerMaxIndex {
  z-index: 999999 !important; }

@keyframes pulse_animation {
  0% {
    transform: scale(1); }
  30% {
    transform: scale(1); }
  40% {
    transform: scale(1.08); }
  50% {
    transform: scale(1); }
  60% {
    transform: scale(1); }
  70% {
    transform: scale(1.05); }
  80% {
    transform: scale(1); }
  100% {
    transform: scale(1); } }

@-webkit-keyframes pulse-shadow {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4); }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0); }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0); } }

@keyframes pulse-shadow {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4); }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 10px rgba(204, 169, 44, 0); }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0); } }

.pulse-icon {
  display: block;
  border-radius: 10em;
  cursor: pointer;
  animation: pulse-shadow 2s infinite; }
  .pulse-icon:hover {
    animation: none; }

.warning {
  display: block;
  padding: 10px 20px;
  font-size: 18px;
  line-height: 22px;
  font-weight: 300;
  position: relative;
  background: var(--accent-color);
  color: #4b4b4b;
  border-radius: 2px;
  margin: 10px 0; }
  .warning p {
    color: #fff; }
  .warning.error {
    background: rgba(252, 68, 88, 0.5) !important; }

.pulse span, .pulse path {
  animation-name: pulse_animation;
  animation-duration: 2000ms;
  transform-origin: center;
  animation-iteration-count: infinite;
  animation-timing-function: linear; }

.custom-btn {
  overflow: hidden;
  position: relative; }

.custom-btn:before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  opacity: 0;
  z-index: 1;
  -webkit-transition: ease 0.2s;
  transition: ease 0.2s; }

.custom-btn:after {
  content: "";
  display: block;
  width: 30px;
  height: 300px;
  margin-left: 60px;
  background: #fff;
  position: absolute;
  left: -40px;
  top: -150px;
  z-index: 1;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: all 0.1s;
  transition: all 0.1s;
  -webkit-animation-name: slideme;
  animation-name: slideme;
  -webkit-animation-duration: 2.5s;
  animation-duration: 2.5s;
  -webkit-animation-delay: 0.05s;
  animation-delay: 0.05s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite; }

@-webkit-keyframes slideme {
  0% {
    left: -30px;
    margin-left: 0px; }
  40% {
    left: 110%;
    margin-left: 80px; }
  100% {
    left: 110%;
    margin-left: 80px; } }

.wrap-select-custom {
  width: 100px !important;
  margin: 0;
  margin-bottom: 0 !important;
  flex-shrink: 0; }
  .wrap-select-custom input {
    height: 35px !important;
    margin-top: 0; }
  .wrap-select-custom span {
    right: 0px; }

.opacity0 {
  opacity: 0 !important; }

.titleWithSelect {
  display: flex;
  align-items: center; }
  .titleWithSelect span {
    flex-shrink: 0;
    display: inline-flex;
    margin-right: 20px; }

.require {
  position: relative;
  right: -4px;
  top: -2px;
  color: var(--accent-color); }

.container70 {
  padding: 0 70px; }
  @media (max-width: 1023.8px) {
    .container70 {
      padding: 0; } }

.color-green {
  color: #4caf50; }

.justify-center {
  display: flex;
  flex-direction: column;
  align-items: center; }

.content-center {
  display: flex;
  align-items: center;
  justify-content: center; }

.max969 {
  max-width: 969px;
  margin-left: auto;
  margin-right: auto; }

.fz2550 {
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 50px;
  text-align: center; }

.fz2530 {
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 30px;
  text-align: center; }

.text-left {
  text-align: left !important; }

.fz1830 {
  font-size: 18px;
  line-height: 30px; }
  @media (max-width: 575.8px) {
    .fz1830 {
      font-size: 16px;
      line-height: 26px; } }

.fz1628 {
  font-size: 16px;
  line-height: 28px; }
  @media (max-width: 575.8px) {
    .fz1628 {
      font-size: 14px;
      line-height: 28px; } }
  .fz1628 p, .fz1628 a {
    font-size: 16px;
    line-height: 28px; }
    @media (max-width: 575.8px) {
      .fz1628 p, .fz1628 a {
        font-size: 14px;
        line-height: 28px; } }

.fz1426 {
  font-size: 14px;
  line-height: 26px; }

.fw600 {
  font-weight: 600; }

.fw799 {
  font-weight: 700; }

.c57 {
  color: #575757; }

.btn-orange {
  color: var(--accent-color);
  cursor: pointer; }

.ml10 {
  margin-left: 10px; }

.mr10 {
  margin-right: 10px; }

.ml20 {
  margin-left: 20px; }
  @media (max-width: 575.8px) {
    .ml20 {
      margin-left: 15px; } }

.mr20 {
  margin-right: 20px; }
  @media (max-width: 575.8px) {
    .mr20 {
      margin-right: 15px; } }

.ml30 {
  margin-left: 30px; }
  @media (max-width: 575.8px) {
    .ml30 {
      margin-left: 20px; } }

.mr30 {
  margin-right: 30px; }
  @media (max-width: 575.8px) {
    .mr30 {
      margin-right: 20px; } }

.mt30 {
  margin-top: 30px; }
  @media (max-width: 575.8px) {
    .mt30 {
      margin-top: 15px; } }

.mb30 {
  margin-bottom: 30px; }
  @media (max-width: 575.8px) {
    .mb30 {
      margin-bottom: 15px; } }

.mt50 {
  margin-top: 50px; }
  @media (max-width: 575.8px) {
    .mt50 {
      margin-top: 20px; } }

.mt50i {
  margin-top: 50px !important; }
  @media (max-width: 575.8px) {
    .mt50i {
      margin-top: 20px !important; } }

.mb50 {
  margin-bottom: 50px; }
  @media (max-width: 575.8px) {
    .mb50 {
      margin-bottom: 20px; } }

.mt20 {
  margin-top: 20px; }
  @media (max-width: 575.8px) {
    .mt20 {
      margin-top: 15px; } }

.mb20 {
  margin-bottom: 20px; }
  @media (max-width: 575.8px) {
    .mb20 {
      margin-bottom: 15px; } }

.mb5 {
  margin-bottom: 5px !important; }

.mt5 {
  margin-top: 5px !important; }

.mb0 {
  margin-bottom: 0 !important; }

.mt0 {
  margin-top: 0 !important;
  flex-grow: 1; }

.mt-15 {
  margin-top: -15px !important; }

.w100 {
  width: 100% !important; }

.pt20 {
  padding-top: 20px; }
  @media (max-width: 575.8px) {
    .pt20 {
      padding-top: 15px; } }

.pb20 {
  padding-bottom: 20px; }
  @media (max-width: 575.8px) {
    .pb20 {
      padding-bottom: 15px; } }

.p0 {
  padding: 0 !important; }

.m_auto {
  margin-right: auto;
  margin-left: auto; }

.pt50 {
  padding-top: 50px; }
  @media (max-width: 575.8px) {
    .pt50 {
      padding-top: 20px; } }

.pt30 {
  padding-top: 30px; }
  @media (max-width: 575.8px) {
    .pt30 {
      padding-top: 15px; } }

.pb100 {
  padding-bottom: 100px; }
  @media (max-width: 575.8px) {
    .pb100 {
      padding-bottom: 50px; } }

.pb50 {
  padding-bottom: 50px; }
  @media (max-width: 575.8px) {
    .pb50 {
      padding-bottom: 20px; } }

.pb30 {
  padding-bottom: 30px; }
  @media (max-width: 575.8px) {
    .pb30 {
      padding-bottom: 15px; } }

.title-invent {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 35px;
  color: #575757;
  margin-bottom: 20px; }
  @media (max-width: 575.8px) {
    .title-invent {
      font-size: 19px;
      line-height: 30px;
      margin-bottom: 17px; } }

.description-invent {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #8C8C8C;
  line-height: 28px; }
  .description-invent a {
    color: var(--accent-color) !important; }
  @media (max-width: 575.8px) {
    .description-invent {
      font-size: 14px;
      line-height: 28px; } }

.caption {
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 50px;
  text-align: center;
  color: #575757;
  z-index: 5; }
  .caption b {
    display: flex;
    margin-top: -5px; }
    .caption b span {
      right: 4px !important; }
  @media (max-width: 575.8px) {
    .caption {
      font-size: 22px;
      line-height: 30px; } }

.wrap-loader-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  background: rgba(255, 255, 255, 0.8); }

.wrap-loader {
  margin: 20px 0;
  text-align: center;
  min-height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%; }
  @media (max-width: 767.8px) {
    .wrap-loader svg {
      transform: scale(0.7); } }

.btn-green {
  background: #4CAF50;
  border-radius: 2px;
  width: 262px;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-style: normal;
  font-weight: 600;
  transition: background .34s;
  font-size: 14px;
  text-transform: uppercase; }
  @media (max-width: 575.8px) {
    .btn-green {
      border-radius: 2px;
      width: 100%;
      height: 31px;
      font-weight: 600;
      font-size: 10px;
      max-width: 256px;
      line-height: 26px; } }

.btn-green:hover {
  background: rgba(76, 175, 80, 0.7); }

.btn-green:active {
  background: rgba(76, 175, 80, 0.5); }

.button-orange {
  background: var(--accent-color);
  border-radius: 2px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 26px;
  text-transform: uppercase;
  width: 100%;
  max-width: 500px;
  height: 40px;
  transform: scale(1);
  transition: transform .25s ease-in-out; }
  .button-orange:hover {
    transform: scale(1.05); }
  .button-orange:disabled {
    background: #8C8C8C;
    cursor: not-allowed; }

.button-orange-light {
  width: 100%;
  max-width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--light-accent-color) !important;
  border-radius: 2px;
  font-style: normal;
  font-weight: bold !important;
  font-size: 16px;
  line-height: 26px;
  color: var(--accent-color) !important;
  transition: color .35s ease, background .35s ease !important;
  height: 40px;
  margin: 0 auto;
  padding: 0 20px; }
  .button-orange-light:disabled {
    background: #8c8c8c;
    cursor: not-allowed;
    color: #fff; }

.fz1728 {
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 28px; }

.c8c {
  color: #8C8C8C; }

.space-between {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.relative {
  position: relative; }

.scroll {
  overflow-y: scroll !important; }
  .scroll::-webkit-scrollbar {
    width: 2px; }
  .scroll::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); }
  .scroll::-webkit-scrollbar-thumb {
    background-color: rgba(229, 102, 59, 0.61);
    outline: 1px solid slategrey; }

.ticketIcon {
  position: relative;
  display: inline-flex; }
  .ticketIcon strong {
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    top: 50%;
    position: absolute;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #FFFFFF; }

.textOnButton {
  font-style: italic;
  font-weight: 300;
  font-size: 17px;
  line-height: 28px;
  color: #8C8C8C; }
  .textOnButton span {
    color: var(--accent-color);
    margin-right: 5px;
    font-style: normal;
    font-weight: 600;
    position: relative;
    top: -2px; }

.modalType {
  background: #FAF8F5 !important;
  max-width: 962px; }

.CloseBtn {
  top: 0;
  right: 0;
  position: absolute;
  opacity: 0.9;
  display: inline-flex;
  transition: 0.2s;
  transform: translate(50%, -50%);
  z-index: 20; }
  .CloseBtn svg {
    opacity: 1 !important; }
  .CloseBtn:after {
    content: '';
    width: 15px;
    height: 15px;
    background: #fff;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
    display: block;
    position: absolute;
    z-index: -1;
    border-radius: 10em; }
  .CloseBtn:hover {
    opacity: 1; }
  @media (max-width: 576px) {
    .CloseBtn {
      top: 20px;
      right: 20px;
      font-size: 30px;
      color: #333; } }

b {
  font-style: italic;
  font-weight: 300;
  font-size: 17px;
  line-height: 28px;
  color: #8C8C8C; }

.inputWithCalendar {
  position: relative; }
  .inputWithCalendar input {
    padding-right: 20px; }
  .inputWithCalendar svg, .inputWithCalendar span {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #8c8c8c;
    font-weight: 700; }
    .inputWithCalendar svg path, .inputWithCalendar span path {
      fill: #8c8c8c; }

.text-orange {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 26px;
  text-transform: uppercase;
  color: var(--accent-color); }

.color-orange {
  color: var(--accent-color) !important; }

.overlay-loader {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.9);
  z-index: 10; }

/*
  GLOBAL VARIABLES
  Import this file to any scss files for use variables
  Please, check variables before adding new
  If you rename a variable - rename it everywhere
*/
/* Branding colors */
/**********************************************
              SCREEN VARIABLES
**********************************************/
.input-wrap {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 30px; }
  .input-wrap input::placeholder {
    font-family: var(--font-family);
    font-style: italic;
    font-weight: 300;
    font-size: 16px;
    line-height: 21px;
    color: #8C8C8C; }

.ticketBackground {
  position: relative;
  display: inline-flex;
  cursor: pointer; }
  .ticketBackground span {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    width: 100%;
    height: 100%;
    color: #FFFFFF;
    z-index: 10; }
  .ticketBackground svg {
    z-index: 1; }
  .ticketBackgroundSmall {
    transform: scale(0.7);
    margin-right: -10px; }

.wrap-input {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 90px; }
  @media (max-width: 1199.8px) {
    .wrap-input {
      grid-template-columns: 1fr;
      grid-column-gap: 0; } }

.labelInput {
  font-size: 16px;
  line-height: 28px;
  color: #8C8C8C; }
  @media (max-width: 575.8px) {
    .labelInput {
      font-size: 14px;
      line-height: 25px; } }

.defaultInput {
  border: 1px solid #8C8C8C;
  box-sizing: border-box;
  border-radius: 2px;
  width: 100%;
  height: 46px;
  display: flex;
  align-items: center; }
  @media (max-width: 575.8px) {
    .defaultInput {
      width: 100%; } }

input[type='checkbox'] ~ label {
  margin: 0.2em;
  cursor: pointer;
  padding: 0.2em;
  color: #8C8C8C;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  display: flex;
  flex-shrink: 0;
  line-height: 28px;
  align-items: center; }
  @media (max-width: 575.8px) {
    input[type='checkbox'] ~ label {
      font-size: 14px;
      line-height: 28px;
      align-items: flex-start; } }

.checkbox {
  display: none; }

.checkbox:checked + label:after {
  opacity: 1; }

.checkbox + label:after {
  content: '\2714';
  opacity: 0;
  position: absolute;
  transform: scale(0.7) translateY(calc(-50% - 5px));
  color: #fff;
  left: 4px;
  top: 50%;
  transition: opacity .35s ease; }
  @media (max-width: 575.8px) {
    .checkbox + label:after {
      transform: scale(0.7) translateX(3px) translateY(2px);
      top: 3px; } }

.checkbox + label:before {
  content: "";
  border: 0.1em solid #8C8C8C;
  border-radius: 0.2em;
  display: inline-block;
  width: 16px;
  height: 16px;
  padding-left: 0;
  padding-bottom: 0;
  margin-right: 10px;
  vertical-align: bottom;
  color: transparent;
  flex-shrink: 0;
  transition: .2s; }
  @media (max-width: 575.8px) {
    .checkbox + label:before {
      margin-top: 7px;
      margin-right: 15px; } }

.checkbox + label {
  position: relative; }

.checkbox + label:active:before {
  transform: scale(0); }

.checkbox:checked + label:before {
  background-color: #4CAF50;
  border-color: #4CAF50;
  color: #fff; }

.checkbox:disabled + label:before {
  transform: scale(1);
  border-color: #aaa; }

.checkbox:checked:disabled + label:before {
  transform: scale(1);
  background-color: #bfb;
  border-color: #bfb; }

@media (max-width: 575.8px) {
  input, textarea, select {
    font-size: 16px; } }

.simpleSelect {
  position: relative;
  margin-bottom: 20px; }

.simpleInput {
  position: relative;
  margin-bottom: 20px; }
  .simpleInput label {
    font-style: normal;
    font-weight: 600 !important;
    font-size: 17px !important;
    line-height: 28px !important;
    color: #8C8C8C !important; }
    .simpleInput label span {
      color: var(--accent-color);
      position: relative;
      top: -3px;
      left: 2px; }
  .simpleInput input {
    box-shadow: 0px 4px 3px rgba(222, 222, 222, 0.09);
    border-radius: 2px;
    border: 1px solid transparent !important;
    height: 46px;
    width: 100%;
    background: #FFFFFF;
    font-size: 17px;
    padding: 10px;
    line-height: 28px; }
    .simpleInput input:focus, .simpleInput input:active {
      border: 1px solid #8C8C8C !important; }
  .simpleInput textarea {
    min-height: 200px;
    box-shadow: 0px 4px 3px rgba(222, 222, 222, 0.09);
    border-radius: 2px;
    border: 1px solid transparent !important;
    background: #FFFFFF;
    font-size: 17px;
    line-height: 28px;
    border-radius: 2px;
    padding: 10px; }
    .simpleInput textarea:focus, .simpleInput textarea:active {
      border: 1px solid #8C8C8C !important; }
  .simpleInput select {
    box-shadow: 0px 4px 3px rgba(222, 222, 222, 0.09);
    border-radius: 2px;
    border: 1px solid transparent !important;
    height: 46px;
    width: 100%;
    transition: border .35s ease;
    font-size: 17px;
    padding: 10px;
    line-height: 28px;
    position: relative; }
    .simpleInput select option {
      width: 30px; }
    .simpleInput select:focus, .simpleInput select:active {
      border: 1px solid #8C8C8C !important; }

.simpleInputBorder {
  margin-bottom: 0; }
  .simpleInputBorder input {
    border: 1px solid #8C8C8C !important; }

.hideBorder input {
  border: 1px solid transparent !important;
  transition: border .35s ease; }

.borderNone {
  border: 1px solid transparent !important; }
  .borderNone * {
    border: none; }

.column {
  display: flex;
  flex-direction: column; }

.fz17 {
  font-size: 17px !important; }
  .fz17 * {
    font-size: 17px !important; }

.radio-control {
  display: flex;
  color: #8C8C8C;
  font-style: normal;
  width: 100%;
  justify-content: space-between;
  font-weight: 600;
  font-size: 14px;
  line-height: 26px;
  text-transform: uppercase; }
  @media (max-width: 767.8px) {
    .radio-control {
      flex-direction: column; } }
  .radio-control label {
    display: flex;
    width: 50%;
    padding: 0 20px;
    justify-content: center;
    align-items: center; }
    @media (max-width: 767.8px) {
      .radio-control label {
        width: 100%; } }
  .radio-control input[type=radio] {
    position: absolute;
    left: -1000px;
    opacity: 0;
    visibility: hidden; }

.radio {
  width: 17px;
  height: 17px;
  border-radius: 10em;
  border: 1.2px solid #8C8C8C;
  transition: border .35s ease, color .35s ease;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px; }
  .radio span {
    background: var(--accent-color);
    width: 7px;
    height: 7px;
    transform: scale(0);
    display: block;
    opacity: 0;
    border-radius: 10em;
    transition: opacity .35s ease, transform .35s ease; }

.active {
  color: var(--accent-color); }
  .active .radio {
    border: 1.2px solid var(--accent-color); }
    .active .radio span {
      transform: scale(1.1);
      opacity: 1; }

.input_outline {
  position: absolute;
  bottom: 0;
  width: 97%;
  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 20px; }
  .input_outline span {
    position: relative;
    display: block;
    height: 1px;
    width: 100%;
    background: #8C8C8C; }

input[type='text'], input[type='password'] {
  transition: border .34s ease; }
  input[type='text']:focus, input[type='text']:active, input[type='password']:focus, input[type='password']:active {
    border: 1px solid #8C8C8C !important; }

.zIndex10 {
  z-index: 10 !important; }

.orange {
  background-color: var(--accent-color) !important; }

.center {
  margin-left: auto;
  margin-right: auto; }

.text-left {
  margin-left: 0;
  margin-top: 20px; }

@keyframes upAndDown {
  0% {
    transform: translateY(0); }
  50% {
    transform: translateY(5px); }
  100% {
    transform: translateY(-5px); } }

.upAndDown {
  animation: upAndDown 1s ease-in-out alternate infinite; }

.popup-content span, .popup-content strong {
  text-align: center; }

.bottom-block {
  position: fixed;
  top: 70px;
  left: 0;
  width: 100%;
  background: #fff;
  box-shadow: 4px -4px 16px rgba(11, 12, 36, 0.03);
  z-index: 9;
  transform: translateY(-400%);
  transition: transform .35s ease; }
  @media (max-width: 767.8px) {
    .bottom-block {
      top: 70px; } }
  @media (max-width: 575.8px) {
    .bottom-block {
      top: 58px; } }

.show {
  transform: translateY(0); }

.bottom-inner {
  height: 60px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 30px; }
  @media (max-width: 575.8px) {
    .bottom-inner {
      justify-content: center; } }

.selected input {
  border: 1px solid var(--accent-color) !important; }

.selected button {
  background-color: var(--accent-color); }
  .selected button:hover {
    border: 0.84615px solid rgba(255, 214, 87, 0.7);
    background: rgba(255, 214, 87, 0.7); }
  .selected button:active {
    border: 0.84615px solid rgba(255, 214, 87, 0.5);
    background: rgba(255, 214, 87, 0.5); }

.promoError input {
  border: 1px solid #fc4458 !important; }

.promoSuccess input {
  border: 1px solid #4CAF50 !important; }

.promoSuccess button {
  background-color: #4CAF50; }
  .promoSuccess button:hover {
    border: 0.84615px solid rgba(76, 175, 80, 0.7);
    background: rgba(76, 175, 80, 0.7); }
  .promoSuccess button:active {
    border: 0.84615px solid rgba(76, 175, 80, 0.5);
    background: rgba(76, 175, 80, 0.5); }

.promo-incorrect-text {
  position: absolute;
  font-size: 11px;
  left: 0;
  bottom: 0;
  color: #fc4458 !important;
  transition: transform .35s ease;
  transform: translateY(0px); }

.promo-incorrect-text-show {
  transform: translateY(25px); }

.promoUnsuccess input {
  border: 1px solid #fc4458 !important; }

.promoUnsuccess button {
  background-color: #fc4458; }
  .promoUnsuccess button:hover {
    border: 0.846146px solid #fc4458;
    background: #fc4458; }
  .promoUnsuccess button:active {
    border: 0.84615px solid rgba(252, 68, 88, 0.5);
    background: rgba(252, 68, 88, 0.5); }

.wrap-checkbox {
  margin-bottom: 40px; }
  .wrap-checkbox > div {
    display: flex;
    align-items: flex-start;
    margin-bottom: 10px; }
    .wrap-checkbox > div > div {
      transform: translateY(7px); }
  .wrap-checkbox label {
    cursor: pointer;
    color: #8C8C8C;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    display: inline;
    margin-left: 10px;
    line-height: 28px;
    align-items: center; }
    .wrap-checkbox label a {
      color: var(--accent-color); }
    @media (max-width: 575.8px) {
      .wrap-checkbox label {
        font-size: 14px;
        flex-wrap: wrap;
        line-height: 28px;
        align-items: flex-start; } }

.tickets-description {
  margin-bottom: 45px; }

.s-tickets {
  margin-bottom: 80px;
  margin-top: 50px;
  position: relative;
  z-index: 15; }

.invent {
  background: #FAF8F5;
  overflow: hidden; }
  @media (max-width: 575.8px) {
    .invent .container {
      padding: 0 16px; } }

.wrap-items {
  position: relative;
  border-left: 1px solid #c7c6c5;
  box-sizing: border-box;
  box-shadow: 0px 4.96955px 3.72717px rgba(222, 222, 222, 0.09);
  margin-left: 20px;
  margin-right: 80px;
  left: 0;
  opacity: 1;
  transition: opacity .35s ease, transform .35s ease;
  transform: translateY(0);
  z-index: 20; }
  @media (max-width: 767.8px) {
    .wrap-items {
      margin-right: 10px; } }
  @media (max-width: 575.8px) {
    .wrap-items {
      margin-right: 0; } }

.hideForm {
  overflow: hidden;
  position: absolute;
  left: -100000px;
  opacity: 0;
  transform: translateY(-40px); }

.slide-down {
  background: #FFFFFF;
  box-shadow: 4px 4px 16px rgba(11, 12, 36, 0.03);
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  width: 100%; }
  @media (max-width: 575.8px) {
    .slide-down {
      height: 34px; }
      .slide-down svg {
        width: 10px !important; } }

.item {
  margin-top: 30px;
  display: flex;
  align-items: center;
  position: relative; }
  @media (max-width: 575.8px) {
    .item {
      width: calc(100% + 20px);
      transform: translateX(-19px); } }

.item:first-child {
  margin-top: 0 !important; }

.item-number {
  width: 50px;
  height: 50px;
  border-radius: 10em;
  background: #FFFFFF;
  border: 1px solid #C7C6C5;
  color: #c7c6c5;
  box-sizing: border-box;
  box-shadow: 4px 4px 16px rgba(11, 12, 36, 0.03);
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  flex-shrink: 0;
  transform: translateX(-50%);
  padding-top: 4px; }
  @media (max-width: 767.8px) {
    .item-number {
      width: 40px;
      font-size: 16px;
      height: 40px; } }
  @media (max-width: 575.8px) {
    .item-number {
      display: none; } }

.item-number.active {
  border: 1px solid var(--accent-color);
  color: var(--accent-color); }

.item-wrap {
  background: #FFFFFF;
  box-shadow: 4px 4px 16px rgba(11, 12, 36, 0.03);
  margin-left: 20px;
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: center; }
  .item-wrap h5 {
    display: flex;
    align-items: center; }
    @media (max-width: 575.8px) {
      .item-wrap h5 {
        align-items: flex-start; }
        .item-wrap h5 svg {
          transform: translateY(7px);
          width: 14px;
          height: 14px; } }
    .item-wrap h5 .saved-ticket {
      margin-left: 10px; }
      @media (max-width: 575.8px) {
        .item-wrap h5 .saved-ticket {
          display: flex;
          flex-direction: column; } }
  @media (max-width: 767.8px) {
    .item-wrap {
      margin-left: 0; } }

.item-header {
  display: flex;
  padding: 30px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 83px;
  flex-grow: 1;
  cursor: pointer; }
  @media (max-width: 1023.8px) {
    .item-header {
      min-height: 60px;
      padding: 20px; } }
  @media (max-width: 575.8px) {
    .item-header {
      padding: 20px 16px;
      align-items: flex-start; } }

.item-content {
  width: 100%;
  transition: opacity .35s ease-in-out, transform .35s ease-in-out;
  opacity: 0;
  transform: translateY(-30px);
  position: absolute;
  visibility: hidden;
  padding: 0 30px 30px;
  z-index: 10; }
  @media (max-width: 575.8px) {
    .item-content {
      padding: 0 16px 16px; } }

.showItem {
  position: relative;
  opacity: 1;
  transform: translateY(0px);
  visibility: visible; }

.item-fields {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(2, minmax(calc(50% - 30px), 1fr));
  grid-column-gap: 30px;
  align-items: flex-end; }
  @media (max-width: 1023.8px) {
    .item-fields {
      display: flex;
      flex-direction: column; }
      .item-fields > div {
        width: 100%; } }

.question {
  position: relative;
  padding-bottom: 25px;
  flex-shrink: 0; }

.text-help {
  position: absolute;
  top: 81px; }
  @media (max-width: 575.8px) {
    .text-help {
      position: relative;
      top: 5px; } }
  .text-help a {
    color: var(--accent-color) !important; }

.text-error {
  position: relative;
  margin-bottom: 0;
  font-size: 0.8rem; }

.sale {
  padding: 15px 0 30px 70px;
  text-align: center; }
  .sale p {
    min-height: 28px; }
  @media (max-width: 767.8px) {
    .sale {
      padding-left: 40px; } }
  @media (max-width: 575.8px) {
    .sale {
      padding-left: 20px;
      padding-right: 39px;
      padding-bottom: 15px; } }
  .sale p {
    font-family: Open Sans;
    font-size: 16px;
    line-height: 28px;
    color: var(--accent-color); }
    @media (max-width: 575.8px) {
      .sale p {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-family: Open Sans;
        font-size: 14px;
        line-height: 25px;
        text-align: center; }
        .sale p span {
          margin-left: 0;
          margin-top: 10px; } }
    .sale p span {
      margin-left: 7px; }

.add-item {
  padding: 30px 0;
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center; }
  .add-item span {
    margin-left: 10px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 26px;
    color: var(--accent-color);
    text-transform: uppercase; }
  @media (max-width: 575.8px) {
    .add-item {
      padding: 15px 0; }
      .add-item span {
        font-weight: 600;
        font-size: 10px;
        line-height: 26px;
        text-transform: uppercase; }
      .add-item svg {
        width: 13px; } }

.header-arrow svg {
  transform: rotate(90deg); }
  @media (max-width: 575.8px) {
    .header-arrow svg {
      position: relative;
      top: 7px; } }

.active svg {
  transform: rotate(-90deg); }

.item-delete {
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(50%, -50%);
  cursor: pointer;
  transition: opacity .35s ease; }
  .item-delete:hover {
    opacity: .8; }
  .item-delete:active {
    opacity: .7; }
  @media (max-width: 575.8px) {
    .item-delete svg {
      width: 16px !important; } }

.save {
  background: var(--accent-color);
  border-radius: 2px;
  width: 158px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 26px;
  text-align: center;
  text-transform: uppercase;
  margin: 0 auto;
  color: #ffffff;
  transition: transform .25s ease-in-out;
  transform: scale(1); }
  .save:hover {
    transform: scale(1.05); }

.ticket_is_empty {
  display: flex;
  align-items: center; }
  .ticket_is_empty p {
    min-width: 171px;
    font-size: 18px !important;
    margin-bottom: 0 !important; }

.t-category {
  margin-bottom: 30px;
  position: relative;
  z-index: 10; }

.t-header {
  background: #FFFFFF;
  box-shadow: 4px 4px 16px rgba(11, 12, 36, 0.03);
  border-radius: 2px;
  padding: 20px 30px;
  position: relative;
  min-height: 127px;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  z-index: 10; }
  @media (max-width: 1023.8px) {
    .t-header {
      padding: 20px; } }
  @media (max-width: 767.8px) {
    .t-header {
      flex-direction: column; } }

.t-title {
  width: 280px;
  color: #575757;
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  padding-right: 20px;
  flex-direction: column;
  justify-content: center; }
  .t-title span {
    font-family: Comfortaa;
    font-weight: bold;
    font-size: 20px;
    line-height: 35px;
    text-transform: uppercase; }
    @media (max-width: 575.8px) {
      .t-title span {
        font-size: 18px; } }
  .t-title small {
    margin-top: 3px;
    font-family: Open Sans;
    font-weight: normal;
    font-size: 16px;
    line-height: 1.4rem;
    display: block; }
    @media (max-width: 767.8px) {
      .t-title small {
        margin-bottom: 20px; } }
  @media (max-width: 767.8px) {
    .t-title {
      max-width: 100%;
      width: 100%;
      text-align: center;
      padding-right: 0; } }

.wrap-count {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 1; }
  @media (max-width: 1023.8px) {
    .wrap-count {
      display: grid;
      grid-template-areas: "price promo" "count promo";
      grid-column-gap: 40px; } }
  @media (max-width: 767.8px) {
    .wrap-count {
      padding-left: 20px;
      padding-right: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center; } }

.count {
  display: flex;
  align-items: center; }
  @media (max-width: 1023.8px) {
    .count {
      align-self: flex-start;
      grid-area: count; } }
  @media (max-width: 767.8px) {
    .count {
      align-self: center;
      order: 2; } }
  .count button {
    background: #8C8C8C;
    border: 0.84615px solid #8C8C8C;
    box-sizing: border-box;
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    position: relative; }
    .count button span:after {
      content: '';
      display: block;
      background: #FFFFFF;
      border-radius: 0.974012px;
      position: absolute;
      width: 13px;
      height: 1.7px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
    .count button.plus span:before {
      content: '';
      position: absolute;
      background: #FFFFFF;
      border-radius: 0.974012px;
      display: block;
      height: 13px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 1.7px; }
  .count input {
    border: 1px solid #8C8C8C;
    border-radius: 8.33321px;
    width: 61px;
    text-align: center;
    font-family: Comfortaa;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #8C8C8C;
    height: 28px;
    margin: 0 20px; }

.price {
  margin-left: 50px;
  font-family: Comfortaa;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 35px;
  text-align: center;
  position: relative;
  color: #575757;
  margin-right: 60px;
  width: 150px; }
  @media (max-width: 1023.8px) {
    .price {
      width: auto;
      grid-area: price;
      max-width: 150px;
      align-self: flex-end;
      margin: 0; } }
  @media (max-width: 767.8px) {
    .price {
      align-self: center;
      order: 1; } }
  @media (max-width: 575.8px) {
    .price {
      font-size: 17px; } }
  .price small {
    font-family: Open Sans;
    font-size: 14px;
    right: -35px;
    top: -12px;
    line-height: 28px;
    position: absolute; }
    @media (max-width: 575.8px) {
      .price small {
        font-size: 12px;
        right: -30px; } }
  .price span {
    position: relative; }

.promo {
  position: relative;
  padding-left: 86px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-right: 10px; }
  @media (max-width: 1023.8px) {
    .promo {
      padding-right: 0;
      padding-left: 66px;
      grid-area: promo; } }
  @media (max-width: 767.8px) {
    .promo {
      align-self: center;
      margin-top: 20px;
      padding-left: 0;
      order: 3; } }
  .promo p {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
    color: #8C8C8C;
    margin-bottom: 5px; }
    @media (max-width: 1023.8px) {
      .promo p {
        font-size: 14px;
        line-height: 26px; } }

.promo-item {
  transform: translateY(-15px);
  position: relative;
  min-width: 150px; }
  @media (max-width: 1023.8px) {
    .promo-item {
      transform: translateY(0); } }

.input-wrap {
  height: 35px;
  box-sizing: border-box;
  margin-bottom: 6px;
  grid-column-gap: 0; }

.input-wrap * {
  box-sizing: border-box; }

.line {
  position: absolute;
  left: 0;
  width: 36px;
  height: 100%;
  display: block; }
  @media (max-width: 767.8px) {
    .line {
      display: none; } }
  @media (max-width: 575.8px) {
    .line {
      display: block;
      position: relative;
      margin: 0 auto;
      height: 40px;
      left: auto;
      transform: rotate(90deg); } }

.active button {
  border: 0.8px solid var(--accent-color);
  background: var(--accent-color);
  transform: scale(1);
  transition: transform .25s ease-in-out; }
  .active button:hover {
    transform: scale(1.05); }

.active input {
  border: 1px solid var(--accent-color);
  color: var(--accent-color); }

.totalSum {
  display: flex;
  align-items: center;
  margin-top: 15px;
  font-family: "Open Sans";
  color: #8C8C8C;
  font-size: 16px;
  justify-content: center; }
  .totalSum p {
    font-size: 16px; }
  .totalSum span {
    display: inline-flex;
    margin-left: 15px;
    position: relative;
    font-weight: 600;
    color: #575757;
    transform: translateY(-1px); }
    .totalSum span small {
      position: relative;
      top: -8px;
      font-size: 10px;
      text-transform: uppercase; }

/*
  GLOBAL VARIABLES
  Import this file to any scss files for use variables
  Please, check variables before adding new
  If you rename a variable - rename it everywhere
*/
/* Branding colors */
/**********************************************
              SCREEN VARIABLES
**********************************************/
.Component {
  box-shadow: 0px 4px 3px rgba(142, 142, 142, 0.09);
  position: relative;
  z-index: 6;
  align-items: center;
  display: flex; }

.Input {
  padding: 18px 5px 13px;
  flex-grow: 1;
  background: #FFFFFF;
  border-radius: 2px;
  font-size: 16px;
  line-height: 1.7em;
  margin: 0;
  border: 1px solid transparent;
  height: 60px;
  color: #575757; }
  .InputPromo {
    border-radius: 2px 0 0 2px !important;
    padding: 10px !important; }

.Button {
  background: var(--accent-color);
  position: relative;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 25px;
  border-radius: 0 7px 7px 0;
  height: 100%;
  border: 1px solid transparent; }
  .Button:hover {
    background: var(--accent-color);
    border-color: 1px solid var(--accent-color); }
  .Button[disabled], .Button:active {
    background: #F2B39D;
    border-color: 1px solid #F2B39D; }

.promo-button {
  background: #8C8C8C;
  width: auto;
  color: #fff;
  padding: 0 5px;
  display: flex;
  justify-content: center;
  align-items: center; }
  .promo-button:hover {
    background: rgba(140, 140, 140, 0.7); }
  .promo-button[disabled], .promo-button:active {
    background: rgba(140, 140, 140, 0.5); }

.input-item {
  width: 80px;
  border: 1px solid #8C8C8C;
  box-sizing: border-box;
  border-radius: 2px;
  padding: 12px 20px;
  padding-right: 50px;
  padding-left: 10px;
  height: 100%; }
  @media (max-width: 767.8px) {
    .input-item {
      width: 250px; } }

.oldPrice {
  font-size: 14px;
  color: rgba(220, 53, 69, 0.7) !important;
  margin-right: 3px;
  text-decoration: line-through; }

.icon-rotate {
  transform: rotate(-180deg); }
